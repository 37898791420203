@font-face {
  font-family: LatoLight;
  src: url("../fonts/LatoLight.eot");
  src: url("../fonts/LatoLight.eot?#iefix") format("embedded-opentype"), url("../fonts/LatoLight.woff") format("woff"), url("../fonts/LatoLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: LatoBold;
  src: url("../fonts/LatoBold.eot");
  src: url("../fonts/LatoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/LatoBold.woff") format("woff"), url("../fonts/LatoBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: LatoRegular;
  src: url("../fonts/LatoRegular.eot");
  src: url("../fonts/LatoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/LatoRegular.woff") format("woff"), url("../fonts/LatoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: LatoLightItalic;
  src: url("../fonts/LatoLightItalic.eot");
  src: url("../fonts/LatoLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/LatoLightItalic.woff") format("woff"), url("../fonts/LatoLightItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
*::selection {
  background: #25a950;
  color: #fff;
}
body a {
  text-decoration: none;
}
body {
  font-family: LatoLight, 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}
body .head {
  background-color: #fff;
}
body .main,
body .comments,
body .contacts,
body .footer {
  min-width: 960px;
}
body button {
  outline: none;
}
body .container {
  margin: 0 auto;
  max-width: 960px;
  min-width: 960px;
}
body .head .header {
  padding-top: 10px;
  height: 49px;
}
body .head .header a,
body .head .header img {
  float: left;
}
body .head .header .contblock {
  margin-top: 6px;
  float: right;
  text-align: center;
}
body .head .header .phone,
body .head .header .city {
  margin: 0;
}
body .head .header .phone {
  font-size: 16px;
}
body .head .header .city {
  font-size: 13px;
}
body .head .content hr {
  margin: 5px 0 0 0;
  height: 1px;
  background: #696969;
  border: none;
}
body .head .content .wrapper {
  display: table;
  width: 100%;
}
body .head .content .wrapper ul {
  margin: 0 auto;
  padding: 0;
  display: table-row;
}
body .head .content .wrapper ul li {
  list-style-type: none;
  display: table-cell;
  margin: 0;
  text-align: center;
  zoom: 1;
  font-size: 15px;
  float: none;
}
body .head .content .wrapper ul li a {
  color: #000;
  display: block;
  text-decoration: none;
  padding: 14px 0 15px 0;
  transition: 0.1s;
}
body .head .content .wrapper ul li a:hover {
  background-color: #4e4e4e;
  color: #fff;
}
body .main {
  background: url("../images/mainBG.png");
  background-size: cover;
  height: 86vh;
  min-height: 542px;
}
body .main h1,
body .main .spec {
  text-align: center;
  color: #fff;
}
body .main h1 {
  font-size: 50px;
  margin: 0;
  padding-top: 80px;
  font-family: LatoBold;
}
body .main .spec {
  font-size: 40px;
  max-width: 790px;
  margin: 0 auto;
}
body .main #popup2 .b-popup-content {
  height: 510px;
}
body .main #popup2 .b-popup-content p {
  width: 530px;
  margin: 0 auto;
}
body .main #popup3 .b-popup-content p {
  width: 600px;
  margin: 0 auto;
}
body .main .b-popup {
  width: 100%;
  min-height: 1208px;
  background-color: rgba(0,0,0,0.5);
  overflow: hidden;
  position: absolute;
  top: 0px;
}
body .main .b-popup .b-popup-content {
  margin: 305px auto 0px auto;
  width: 956;
  height: 473px;
  background-color: #fff;
  z-index: 9999;
}
body .main .b-popup .b-popup-content p {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-top: 55px;
}
body .main .b-popup .b-popup-content ul {
  padding: 0;
  margin-top: 50px;
}
body .main .b-popup .b-popup-content ul li {
  display: inline-block;
  font-size: 20px;
  width: 420px;
  height: 50px;
  list-style: circle;
  padding-left: 50px;
  background: url("../images/dot.png") 5% 20% no-repeat;
}
body .main .b-popup .b-popup-content .button {
  border-radius: 0;
  background: #a10202;
  padding: 35px 70px;
  font-size: 20px;
  border: none;
  color: #fff;
  margin-left: 33%;
  position: relative;
  top: 20px;
}
body .main .b-popup .b-popup-content .close {
  position: absolute;
  top: 243px;
  right: 172px;
}
body .main .b-popup .b-popup-content .close img {
  transition: 0.1s;
}
body .main .b-popup .b-popup-content .close:hover > img {
  opacity: 0.5;
}
body .main .reabils {
  max-width: 795px;
  margin: 86px auto 0 auto;
}
body .main .reabils table tr {
  width: 793px;
}
body .main .reabils table tr td {
  text-align: center;
  vertical-align: middle;
  color: #fff;
  width: 265px;
}
body .main .reabils table .upper td {
  height: 172px;
  font-size: 20px;
  padding-left: 38px;
  padding-right: 38px;
  border-top: 1px solid #fff;
}
body .main .reabils table .upper td:nth-child(1) {
  background-color: #115126;
}
body .main .reabils table .upper td:nth-child(2) {
  background-color: #0e4620;
}
body .main .reabils table .upper td:nth-child(3) {
  background-color: #133c21;
}
body .main .reabils table .downer {
  border-bottom: 1px solid #fff;
}
body .main .reabils table .downer td:nth-child(1) {
  background-color: #0e411e;
}
body .main .reabils table .downer td:nth-child(2) {
  background-color: #0b381a;
}
body .main .reabils table .downer td:nth-child(3) {
  background-color: #0f301a;
}
body .main .reabils table .downer td:hover {
  background-color: #275435;
}
body .main .reabils table .downer td {
  height: 48px;
  transition: 0.2s;
}
body .main .reabils table .downer td a {
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  padding: 15px 87px;
  outline: none;
}
body .container .about h1 {
  padding: 53px 0;
  text-align: center;
  font-size: 36px;
  margin: 0;
}
body .container .about img,
body .container .about .inner_about {
  display: inline-block;
}
body .container .about .inner_about {
  vertical-align: top;
  width: 550px;
  margin-left: 90px;
  margin-top: 60px;
}
body .container .about p {
  font-size: 23px;
}
body .container .about .name {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
body .container .about button {
  background-color: #fff;
  border: 1px solid #4e4e4e;
  width: 197px;
  height: 50px;
  margin-left: 185px;
  margin-top: 60px;
  border-radius: 2px;
  font-size: 17px;
  font-weight: 100;
  transition: 0.1s;
}
body .container .about button:hover {
  background-color: #4e4e4e;
  color: #fff;
}
body .comments {
  background-color: #d9d9d9;
  height: 820px;
}
body .comments .container h1 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 56px;
  margin-top: 60px;
}
body .comments .container .inner_comments {
  height: 570px;
  margin-left: 74px;
}
body .comments .container .inner_comments .comment {
  height: 240px;
}
body .comments .container .inner_comments .comment h4 {
  font-size: 24px;
  font-weight: 100;
  margin: 40px 0 20px 0;
}
body .comments .container .inner_comments .comment p {
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  max-height: 150px;
}
body .comments .container .inner_comments .grad {
  position: relative;
  top: -170px;
}
body .comments .container button {
  width: 196px;
  height: 58px;
  background: transparent;
  border: 1px solid #434343;
  font-size: 18px;
  margin-left: 39.5%;
  font-family: LatoLight;
  transition: 0.1s;
  border-radius: 2px;
}
body .comments .container button:hover {
  background-color: #434343;
  color: #fff;
}
body .contacts {
  height: 310px;
  background-image: url("../images/contactsBG.png");
}
body .contacts .container h1 {
  font-size: 36px;
  color: #fefefe;
  text-align: center;
  margin: 0;
  padding: 60px 0;
}
body .contacts .container .inner_cont {
  margin: 0 auto;
  height: 96px;
}
body .contacts .container .inner_cont .logar,
body .contacts .container .inner_cont .phone,
body .contacts .container .inner_cont .mail {
  display: inline-block;
  float: left;
  height: 96px;
}
body .contacts .container .inner_cont .phone .phoneblock h3,
body .contacts .container .inner_cont .mail .phoneblock h3,
body .contacts .container .inner_cont .phone .inner_mail h3,
body .contacts .container .inner_cont .mail .inner_mail h3 {
  text-align: right;
  font-size: 24px;
  color: #fff;
  font-weight: 100;
  margin: 0;
}
body .contacts .container .inner_cont .logar {
  width: 300px;
}
body .contacts .container .inner_cont .logar .inner {
  margin-top: 18px;
}
body .contacts .container .inner_cont .logar .inner img {
  float: left;
}
body .contacts .container .inner_cont .logar .inner .nametext {
  margin-left: 65px;
}
body .contacts .container .inner_cont .logar .inner .nametext h4,
body .contacts .container .inner_cont .logar .inner .nametext h5 {
  color: #fff;
  font-weight: 100;
  text-align: center;
  margin: 0;
}
body .contacts .container .inner_cont .logar .inner .nametext h4 {
  margin-top: 2px;
  font-size: 24px;
}
body .contacts .container .inner_cont .logar .inner .nametext h5 {
  font-size: 18px;
}
body .contacts .container .inner_cont .phone {
  width: 360px;
}
body .contacts .container .inner_cont .phone .phoneblock {
  height: 43px;
  width: 162px;
  margin: 0 auto;
}
body .contacts .container .inner_cont .phone .phoneblock img {
  float: left;
}
body .contacts .container .inner_cont .phone .phoneblock h3 {
  padding-top: 5%;
}
body .contacts .container .inner_cont .phone p {
  text-align: center;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
}
body .contacts .container .inner_cont .mail {
  width: 290px;
}
body .contacts .container .inner_cont .mail .inner_mail {
  margin: 4px auto 0 auto;
  width: 132px;
}
body .contacts .container .inner_cont .mail .inner_mail img {
  float: left;
}
body .contacts .container .inner_cont .mail .inner_mail h3 {
  padding-top: 2%;
}
body .contacts .container .inner_cont .mail p {
  padding-top: 4px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}
body .partners .container .parts {
  max-width: 870px;
  margin: 40px auto;
}
body .partners .container .parts a {
  outline: none;
  width: 0;
  height: 0;
}
body .partners .container .parts a img {
  height: 96px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: #808080;
/* IE 6-9 */
  transition: 0.2s;
  margin: 0 77px;
  opacity: 0.5;
}
body .partners .container .parts a img:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}
body .partners .container .parts a .oner {
  margin-left: 0;
}
body .partners .container .parts a .laster {
  margin-right: 0;
}
body .footer {
  height: 158px;
  background-color: #4e4e4e;
}
body .footer .container ul {
  margin-top: 25px;
  padding: 25px 0 8px 0;
}
body .footer .container ul a {
  color: #fff;
  font-size: 18px;
  outline: none;
}
body .footer .container ul a li {
  display: inline-block;
  padding: 7px 7px;
  transition: 0.1s;
  margin-right: 57px;
}
body .footer .container ul a .about_last {
  margin-right: 0;
}
body .footer .container ul a li:hover {
  background-color: #fff;
  color: #4e4e4e;
}
body .footer .container a img {
  float: right;
  margin-top: -58px;
  transition: 0.1s;
}
body .footer .container a img:hover {
  opacity: 0.5;
}
body .footer .container hr {
  border: none;
  height: 1px;
  background-color: #fff;
}
body .footer .container p {
  font-size: 18px;
  color: #fff;
}
body .footer .container .buttonup #goTop {
  position: fixed;
  bottom: 50px;
  right: -100px;
}
body .footer .container .buttonup img:hover {
  cursor: pointer;
}
