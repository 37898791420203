.newser .container {
  min-width: 960px;
}
.newser .head {
  background-color: #133c21;
  min-width: 960px;
}
.newser .head .container .header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.newser .head .container .header a img {
  transition: 0.2s;
}
.newser .head .container .header a img:hover {
  opacity: 0.7;
}
.newser .head .container .header .contblock {
  margin-top: 10px;
  color: #fff;
}
.newser .head .content .container hr {
  background-color: #fff;
}
.newser .head .content .container .wrapper ul li a:hover {
  background-color: #fff;
  color: #133c21;
}
.newser .head .content .container .wrapper ul li a {
  outline: none;
  color: #fff;
}
.newser .news .container h1 {
  text-align: center;
  font-size: 36px;
  margin: 60px auto 60px auto;
}
.newser .news .container .inner_news {
  width: 960px;
}
.newser .news .container .inner_news a:nth-child(3n) > .new {
  margin-right: 0;
}
.newser .news .container .inner_news a {
  text-decoration: none;
  width: 0;
  height: 0;
}
.newser .news .container .inner_news a .new {
  display: inline-block;
  width: 272px;
  margin-right: 67px;
  vertical-align: top;
  margin-bottom: 50px;
}
.newser .news .container .inner_news a .new .new_img {
  height: 218px;
  width: 272px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.newser .news .container .inner_news a .new .first {
  background-image: url("../images/new_1.png");
}
.newser .news .container .inner_news a .new .twelv {
  background-image: url("../images/new_2.png");
}
.newser .news .container .inner_news a .new .three {
  background-image: url("../images/new_3.png");
}
.newser .news .container .inner_news a .new .texto {
  font-size: 18px;
}
.newser .news .container .inner_news a .new .texto .data {
  color: #5a5959;
  margin-top: 20px;
  margin-bottom: 20px;
}
.newser .news .container .inner_news a .new .texto .pre {
  max-height: 130px;
  color: #000;
  margin-bottom: 0;
}
