#ie8 body {
  border: 10px solid #f00;
}
/* normalize.css 2012-03-11T12:53 UTC - http://github.com/necolas/normalize.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
/* 1 */
  -webkit-text-size-adjust: 100%;
/* 2 */
  -ms-text-size-adjust: 100%;
/* 2 */
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}
h3 {
  font-size: 1.17em;
  margin: 1em 0;
}
h4 {
  font-size: 1em;
  margin: 1.33em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}
h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 1em 40px;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
p,
pre {
  margin: 1em 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: '';
  content: none;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
dl,
menu,
ol,
ul {
  margin: 1em 0;
}
dd {
  margin: 0 0 0 40px;
}
menu,
ol,
ul {
  padding: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}
img {
  border: 0;
/* 1 */
  -ms-interpolation-mode: bicubic;
/* 2 */
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
/* 1 */
  padding: 0;
  white-space: normal;
/* 2 */
  *margin-left: -7px;
/* 3 */
}
button,
input,
select,
textarea {
  font-size: 100%;
/* 1 */
  margin: 0;
/* 2 */
  vertical-align: baseline;
/* 3 */
  *vertical-align: middle;
/* 3 */
}
button,
input {
  line-height: normal;
/* 1 */
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
/* 1 */
  -webkit-appearance: button;
/* 2 */
  *overflow: visible;
/* 3 */
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
/* 1 */
  padding: 0;
/* 2 */
  *height: 13px;
/* 3 */
  *width: 13px;
/* 3 */
}
input[type="search"] {
  -webkit-appearance: textfield;
/* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
/* 2 */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
/* 1 */
  vertical-align: top;
/* 2 */
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
