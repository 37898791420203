.consult .head {
  background-color: #133c21;
  min-width: 960px;
}
.consult .head .container .header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.consult .head .container .header a img {
  transition: 0.2s;
}
.consult .head .container .header a img:hover {
  opacity: 0.7;
}
.consult .head .container .header .contblock {
  margin-top: 10px;
  color: #fff;
}
.consult .head .content .container hr {
  background-color: #fff;
}
.consult .head .content .container .wrapper ul li a:hover {
  background-color: #fff;
  color: #133c21;
}
.consult .head .content .container .wrapper ul li a {
  outline: none;
  color: #fff;
}
.consult .consulting {
  height: 1000px;
}
.consult .consulting .b-popup {
  width: 100%;
  min-height: 1075px;
  background-color: rgba(0,0,0,0.5);
  overflow: hidden;
  position: absolute;
  top: 112px;
}
.consult .consulting .b-popup .b-popup-content {
  margin: 580px auto 0px auto;
  width: 537px;
  height: 380px;
  background-color: #fff;
  z-index: 9999;
  border-top: 18px solid #a10202;
}
.consult .consulting .b-popup .b-popup-content h1 {
  font-size: 36px;
  text-align: center;
  padding-top: 80px;
  margin: 0;
}
.consult .consulting .b-popup .b-popup-content p {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.consult .consulting .b-popup .b-popup-content .pho {
  margin: 60px 90px 20px 110px;
  float: left;
}
.consult .consulting .b-popup .b-popup-content .tele {
  margin: 60px 50px 0 0;
}
.consult .consulting .b-popup .b-popup-content .mail,
.consult .consulting .b-popup .b-popup-content .box {
  margin-top: 55px;
}
.consult .consulting .b-popup .b-popup-content .mail {
  float: left;
  margin-right: 0px;
  width: 100px;
  right: 0px;
  margin-left: -163px;
}
.consult .consulting .b-popup .b-popup-content .box {
  margin-right: 80px;
  float: right;
}
.consult .consulting .b-popup .b-popup-content .close {
  position: relative;
  top: -310px;
  right: -350px;
}
.consult .consulting .b-popup .b-popup-content .close img {
  transition: 0.1s;
}
.consult .consulting .b-popup .b-popup-content .close:hover > img {
  opacity: 0.5;
}
.consult .consulting .container:last-child {
  margin-bottom: 70px;
}
.consult .consulting .container h1 {
  text-align: center;
  font-size: 36px;
  margin: 50px auto 50px auto;
  width: 720px;
}
.consult .consulting .container .citata {
  width: 360px;
  margin-left: auto;
}
.consult .consulting .container .citata p {
  text-align: right;
  margin: 0;
  line-height: 26px;
  font-size: 18px;
}
.consult .consulting .container img {
  display: block;
  margin: 70px auto 10px auto;
}
.consult .consulting .container table {
  margin: 0 0 0 10px;
}
.consult .consulting .container table tbody {
  text-align: center;
}
.consult .consulting .container table tbody .under td {
  padding: 2px 70px;
  font-size: 18px;
  line-height: 27px;
}
.consult .consulting .container table tbody tr td {
  padding: 20px 56px;
  font-size: 20px;
}
.consult .consulting .container h2 {
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
}
.consult .consulting .container ul {
  padding: 0;
  margin: 0;
}
.consult .consulting .container ul li:nth-child(2n) {
  margin-left: 100px;
}
.consult .consulting .container ul li {
  font-size: 20px;
  display: inline-block;
  width: 390px;
  margin: 13px 0;
  padding-left: 26px;
  background: url("../images/dot.png") 0% 50% no-repeat;
}
.consult .consulting .container a {
  text-align: center;
  display: block;
  margin: 70px auto;
  padding: 35px 0;
  width: 410px;
  outline: none;
  font-size: 20px;
  color: #fff;
  background-color: #a10202;
  font-weight: 600;
  transition: 0.2s;
}
.consult .consulting .container a:hover {
  background-color: #b43535;
}
