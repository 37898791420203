.commento .head {
  background-color: #005a1f;
  min-width: 960px;
}
.commento .head .container .header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.commento .head .container .header a img {
  transition: 0.2s;
}
.commento .head .container .header a img:hover {
  opacity: 0.7;
}
.commento .head .container .header .contblock {
  margin-top: 10px;
  color: #fff;
}
.commento .head .content .container hr {
  background-color: #fff;
}
.commento .head .content .container .wrapper ul li a:hover {
  background-color: #fff;
  color: #133c21;
}
.commento .head .content .container .wrapper ul li a {
  outline: none;
  color: #fff;
}
.commento .inner_commento .container h1 {
  text-align: center;
  font-size: 36px;
  margin: 60px auto 60px auto;
  width: 720px;
}
.commento .inner_commento .container .post .client_info {
  font-size: 28px;
  font-family: LatoRegular, latoLight;
}
.commento .inner_commento .container .post .diagnos,
.commento .inner_commento .container .post .before,
.commento .inner_commento .container .post .after {
  font-size: 20px;
  font-weight: bold;
}
.commento .inner_commento .container .post .diagnos span,
.commento .inner_commento .container .post .before span,
.commento .inner_commento .container .post .after span {
  font-weight: 100;
}
.commento .inner_commento .container .post p {
  margin: 0;
  line-height: 32px;
}
.commento .inner_commento .container .post .post_text {
  font-family: LatoLightItalic, LatoLight, LatoRegular, Arial;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.commento .inner_commento .container .post .imglist {
  max-width: 960px;
  margin: 0 auto;
  display: table-cell;
  padding: 30px 0;
}
.commento .inner_commento .container .post .imglist img {
  display: inline-table;
  margin: 0 55px;
}
.commento .inner_commento .container ul {
  margin: 60px auto;
  text-align: center;
  display: block;
  padding: 0;
}
.commento .inner_commento .container ul a li:hover {
  background-color: #395b45;
  color: #fff;
}
.commento .inner_commento .container ul a li {
  width: 46px;
  height: 34px;
  display: inline-block;
  background: url("../images/pnbg.png") 0% 0% no-repeat;
  padding-top: 12px;
  margin: 0 13px;
  transition: 0.2s;
  font-size: 20px;
}
.commento .inner_commento .container ul a .here:hover {
  background-color: #fff;
  color: #000;
}
.commento .inner_commento .container ul a .here {
  background-image: url("../images/pnbga.png");
}
