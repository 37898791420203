.newone .head {
  background-color: #133c21;
  min-width: 960px;
}
.newone .head .container .header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.newone .head .container .header a img {
  transition: 0.2s;
}
.newone .head .container .header a img:hover {
  opacity: 0.7;
}
.newone .head .container .header .contblock {
  margin-top: 10px;
  color: #fff;
}
.newone .head .content .container hr {
  background-color: #fff;
}
.newone .head .content .container .wrapper ul li a:hover {
  background-color: #fff;
  color: #133c21;
}
.newone .head .content .container .wrapper ul li a {
  outline: none;
  color: #fff;
}
.newone .new .container:last-child {
  margin-bottom: 70px;
}
.newone .new .container img,
.newone .new .container .post,
.newone .new .container ul,
.newone .new .container .postul {
  max-width: 830px;
  margin: 0 auto;
}
.newone .new .container h1 {
  text-align: center;
  font-size: 36px;
  margin: 60px auto 60px auto;
  width: 720px;
}
.newone .new .container img {
  display: block;
  margin: 0 auto 60px auto;
}
.newone .new .container .post {
  margin-bottom: 20px;
}
.newone .new .container .post,
.newone .new .container ul li,
.newone .new .container .postul {
  font-size: 20px;
  line-height: 35px;
}
.newone .new .container ul {
  padding: 0;
}
.newone .new .container ul li {
  list-style-type: none;
}
