.about .head {
  background-color: #005a1f;
  min-width: 960px;
}
.about .head .container .header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.about .head .container .header a img {
  transition: 0.2s;
}
.about .head .container .header a img:hover {
  opacity: 0.7;
}
.about .head .container .header .contblock {
  margin-top: 10px;
  color: #fff;
}
.about .head .content .container hr {
  background-color: #fff;
}
.about .head .content .container .wrapper ul li a:hover {
  background-color: #fff;
  color: #133c21;
}
.about .head .content .container .wrapper ul li a {
  outline: none;
  color: #fff;
}
.about .inner_about .container h1 {
  text-align: center;
  font-size: 36px;
  margin: 60px auto 60px auto;
  width: 720px;
}
.about .inner_about .container p {
  text-indent: 20px;
  font-size: 20px;
  line-height: 36px;
  font-weight: 100;
  margin: 0;
}
.about .inner_about .container .siluet {
  float: left;
  margin: 0 40px 0 0;
  height: 560px;
  width: 384px;
}
.about .inner_about .container .siluet img {
  height: 436px;
}
.about .inner_about .container .siluet .name,
.about .inner_about .container .siluet .profy {
  text-align: center;
  line-height: 18px;
  text-indent: 0;
}
.about .inner_about .container .siluet .name {
  font-size: 20px;
  margin: 20px 0 15px 0;
}
.about .inner_about .container .siluet .profy {
  font-size: 14px;
  margin: 0;
}
.about .inner_about .container .citata {
  text-align: right;
  font-size: 18px;
  padding-left: 670px;
  line-height: 27px;
  margin-bottom: 20px;
}
.about .inner_about .container #slides {
  display: none;
}
.about .inner_about .container #slides .slidesjs-navigation {
  display: none;
}
.about .inner_about .container #slides .diploms {
  height: 610px;
}
.about .inner_about .container #slides .diploms img:nth-child(-n+4) {
  padding-bottom: 60px;
}
.about .inner_about .container #slides .diploms a:nth-child(4n) > img {
  margin-right: 0;
}
.about .inner_about .container #slides .diploms a img {
  height: 270px;
  margin-right: 65px;
  width: 190px;
  cursor: pointer;
}
.about .inner_about .container #slides ul {
  margin: 60px auto;
  padding: 0;
  display: block;
  text-align: center;
}
.about .inner_about .container #slides ul li:hover {
  background-color: #84bb97;
}
.about .inner_about .container #slides ul li {
  display: inline-block;
  transition: 0.2s;
  width: 23px;
  height: 23px;
  margin: 0 7px;
  text-align: left;
  background: url("../images/navigation.png") 0% 50% no-repeat;
}
.about .inner_about .container #slides ul li a {
  padding: 0 7px 6px 6px;
  color: transparent;
}
.about .inner_about .container #slides ul li .active {
  background-color: #133c21;
}
